import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Banner = () => (
  <div className="hero-banner" style={{ "background-color": "#091031" }}>
    <div className="container">
      <div className="hero-banner-content privacy-hero text-center">
        <h2 className="text-white text-uppercase font-weight-bold m-0">
          CALIFORNIA CONSUMER ADDENDUM TO PRIVACY POLICY
        </h2>
        <p className="text-white text-left ml-5 pl-5">
          Effective as of 1/01/2020
        </p>
      </div>
    </div>
  </div>
)

class CaliforniaPrivacyPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="California Privacy Policy | Quandary Consulting Group"
          description="Expand your bottom line. Exceed customer expectations. Experience efficiency like never before.Choose Quandary to guide your workflow optimization."
        />
        <div className="container my-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <p>
                The California Consumer Privacy Act (“CCPA”) was passed on by
                the California State Legislature and signed into law on June 28,
                2018. The law goes into effect starting January 1, 2020. More
                information on the CCPA is available from {""}
                <a
                  aria-label="link"
                  href="https://oag.ca.gov/system/files/attachments/press_releases/CCPA%20Fact%20Sheet%20%2800000002%29.pdf"
                >
                  the California Office of the Attorney General.
                </a>{" "}
              </p>
              <p>
                This statement addresses Quandary Consulting Group’s activities
                as a Business as described in the California Consumer Privacy
                Act. This statement supplements our Privacy Policy. In the event
                of a conflict between this statement and our Privacy Policy,
                this statement shall control as it relates to California
                Residents.
              </p>
              <p className="h4">CCPA Consumer Rights Info</p>
              <p>
                The CCPA enhances privacy rights and consumer protection for
                residents of California by allowing California residents more
                control over how companies collect and use their personal
                information.
              </p>
              <p>Under CCPA, California consumers have the right to:</p>
              <ul>
                <li>
                  request details regarding the personal information we collect
                  (including how we use/ disclose this information), and request
                  corrections to this personal information*
                </li>
                <li>delete their personal information*</li>
                <li>opt-out of any “sales” that may be occurring</li>
                <li>
                  not be discriminated against for exercising these rights
                </li>
              </ul>
              <p>
                *Please note, your right to access and right to deletion are not
                absolute and are subject to certain exceptions.
              </p>
              <p>
                For instance, we cannot disclose specific pieces of Personal
                Information if the disclosure would create a substantial,
                articulable, and unreasonable risk to the security of the
                Personal Information, your account with us or the security of
                the business’s systems of networks.
              </p>
              <p className="h4">How to Exercise Your Rights Under the CCPA</p>
              <p>
                Under our current practices and business implementation, we do
                not sell personal information. California consumers may make a
                request pursuant to their rights under the CCPA by emailing us
                at info@quandarycg.com.
              </p>
              <p>
                Please include “CCPA REQUEST” in the subject line of your email.
              </p>
              <p>
                When a request is made, we may verify your identity to protect
                your privacy and security. We will respond to written rights
                requests within 45 days following receipt at the email address
                stated above. If we receive your request at a different email or
                mailing address, we will respond within a reasonable period of
                time.
              </p>
              <p>
                Please note that we are only required to respond to each
                customer once per calendar year.
              </p>
              <p className="h4">CCPA Disclosures</p>
              <p>
                Here are details about the personal information that we collect
                and share. For more information, please see our Privacy Policy [
                <a
                  aria-label="link"
                  href="https://quandarycg.com/privacy-policy/"
                >
                  link
                </a>
                ].
              </p>

              <p className="h5">Information We Collect</p>
              <p>
                To better engage with you, we may collect certain information
                directly from you, from third parties with whom we partner, and
                from publicly available sources. The below list provides an
                overview of our Personal Information collection practices for
                California residents and includes terms defined under the CCPA,
                including but not limited to the categories of information
                listed:
              </p>
              <ul>
                <li>identifiers such as your name and email address,</li>
                <li>employment information, e.g., where you work,</li>
                <li>
                  commercial information, for example, details of what
                  prospective customers choose to purchase (or not to purchase)
                  from Quandary Consulting Group
                </li>
                <li>
                  inferences we draw about you, for example, based on your
                  relationship with Quandary Consulting Group,
                </li>
                <li>
                  electronic network data such as IP address and user agent.
                </li>
              </ul>
              <p className="h5">
                Information We Disclose for Business Purposes
              </p>
              <p>
                We have limited relationships with third parties to assist us in
                providing the best experience possible when you interact with
                Quandary Consulting Group.
              </p>

              <ul>
                <li>
                  <b>Perform Services/ Short-Term Uses.</b> We share information
                  we collect with tools maintained by third parties to help us
                  connect with you, customize your experience, and provide
                  access to Quandary Consulting Group resources.
                </li>
                <li>
                  <b>Security.</b> We use third-party tools to manage and secure
                  traffic and activities on our website.
                </li>
                <li>
                  <b>Audit.</b> We rely on third-party platforms and service
                  providers to help us meet our financial, governance, and
                  regulatory obligations and to support our analytics teams.
                </li>
              </ul>
              <p>
                Whenever possible we share information in an aggregate,
                de-identified, or anonymized format. Information is only shared
                to the extent necessary to perform these functions.
              </p>
              <p>
                As stated in our privacy policy, “These [third party] service
                providers are contractually required to maintain the
                confidentiality of the information we provide them. The
                contracts outline the appropriate use and handling of your
                information and prohibit third parties from using any of your
                personal information for purposes unrelated to the product or
                service for which they’ve been contracted.”
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CaliforniaPrivacyPage
